var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{attrs:{"id":'exercise' + _vm.iSkill + _vm.iSection + _vm.iExercise}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.iExercise + 1)+". "+_vm._s(_vm.exercise.exercise_title))]),_c('v-icon',{attrs:{"right":""},on:{"click":function($event){return _vm.editExercise(
          _vm.iSkill,
          _vm.iSection,
          _vm.iExercise,
          _vm.skill.id,
          _vm.section.id,
          _vm.exercise.id
        )}}},[_vm._v("mdi-pencil-outline")]),_c('v-icon',{attrs:{"right":""},on:{"click":function($event){return _vm.deleteExercise(_vm.iSkill, _vm.iSection, _vm.iExercise, _vm.exercise.id)}}},[_vm._v("mdi-delete")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""},on:{"click":function($event){return _vm.openDialogSortExercise(_vm.iSkill, _vm.iSection, _vm.section)}}},'v-icon',attrs,false),on),[_vm._v("mdi-sort")])]}}])},[_c('span',[_vm._v("Sắp xếp thứ tự bài tập")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
            _vm.section.element_type == 'Audio' || _vm.section.element_type == 'Video'
          )?_c('v-icon',_vm._g(_vm._b({attrs:{"right":""},on:{"click":function($event){return _vm.openDialogAudioTime(
              _vm.iSkill,
              _vm.iSection,
              _vm.iExercise,
              _vm.section,
              _vm.exercise
            )}}},'v-icon',attrs,false),on),[_vm._v("mdi-alarm-plus")]):_vm._e()]}}])},[_c('span',[_vm._v("Thêm timer cho element")])])],1),(
      _vm.exercise.sub_element_type == null && _vm.exercise.sub_element_data == null
    )?_c('v-btn',{staticClass:"font-weight-bold ma-2",attrs:{"outlined":"","large":"","color":"deep-purple"},on:{"click":function($event){return _vm.openDialogSubElement(
        _vm.iSkill,
        _vm.iSection,
        _vm.iExercise,
        _vm.section.id,
        _vm.exercise.id,
        'exercise'
      )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Bổ sung sub element ")],1):_vm._e(),(
      _vm.exercise.sub_element_type != null && _vm.exercise.sub_element_data != null
    )?_c('v-btn',{staticClass:"font-weight-bold ma-2",attrs:{"outlined":"","large":"","color":"deep-purple"},on:{"click":function($event){return _vm.editSubElement(
        _vm.iSkill,
        _vm.iSection,
        _vm.iExercise,
        _vm.section.id,
        _vm.exercise.id,
        'exercise'
      )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Cập nhật sub element ")],1):_vm._e(),(
      _vm.exercise.sub_element_type != null && _vm.exercise.sub_element_data != null
    )?_c('v-btn',{staticClass:"font-weight-bold",attrs:{"outlined":"","large":"","color":"red"},on:{"click":function($event){return _vm.deleteSubElement(_vm.iSkill, _vm.iSection, _vm.iExercise)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Xóa sub element (Exericse) ")],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }