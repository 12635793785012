<template>
  <div>
    <h4 :id="'exercise' + iSkill + iSection + iExercise">
      <span class="font-weight-bold"
        >{{ iExercise + 1 }}. {{ exercise.exercise_title }}</span
      >
      <v-icon
        @click="
          editExercise(
            iSkill,
            iSection,
            iExercise,
            skill.id,
            section.id,
            exercise.id
          )
        "
        right
        >mdi-pencil-outline</v-icon
      >
      <v-icon
        @click="deleteExercise(iSkill, iSection, iExercise, exercise.id)"
        right
        >mdi-delete</v-icon
      >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            @click="openDialogSortExercise(iSkill, iSection, section)"
            v-bind="attrs"
            v-on="on"
            right
            >mdi-sort</v-icon
          >
        </template>
        <span>Sắp xếp thứ tự bài tập</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="
              section.element_type == 'Audio' || section.element_type == 'Video'
            "
            v-bind="attrs"
            v-on="on"
            @click="
              openDialogAudioTime(
                iSkill,
                iSection,
                iExercise,
                section,
                exercise
              )
            "
            right
            >mdi-alarm-plus</v-icon
          >
        </template>
        <span>Thêm timer cho element</span>
      </v-tooltip>
    </h4>
    <v-btn
      v-if="
        exercise.sub_element_type == null && exercise.sub_element_data == null
      "
      @click="
        openDialogSubElement(
          iSkill,
          iSection,
          iExercise,
          section.id,
          exercise.id,
          'exercise'
        )
      "
      outlined
      large
      class="font-weight-bold ma-2"
      color="deep-purple"
    >
      <v-icon left>mdi-plus</v-icon>Bổ sung sub element
    </v-btn>
    <v-btn
      v-if="
        exercise.sub_element_type != null && exercise.sub_element_data != null
      "
      @click="
        editSubElement(
          iSkill,
          iSection,
          iExercise,
          section.id,
          exercise.id,
          'exercise'
        )
      "
      outlined
      large
      class="font-weight-bold ma-2"
      color="deep-purple"
    >
      <v-icon left>mdi-pencil</v-icon>Cập nhật sub element
    </v-btn>
    <v-btn
      outlined
      large
      class="font-weight-bold"
      color="red"
      @click="deleteSubElement(iSkill, iSection, iExercise)"
      v-if="
        exercise.sub_element_type != null && exercise.sub_element_data != null
      "
    >
      <v-icon left>mdi-delete</v-icon> Xóa sub element (Exericse)
    </v-btn>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import constantTestBuilder from "../../mixins/Support/constantTestBuilder";
export default {
  name: "ExerciseAction",
  props: {
    skill: {
      type: Object,
      default() {
        return {};
      },
    },
    section: {
      type: Object,
      default() {
        return {};
      },
    },
    exercise: {
      type: Object,
      default() {
        return {};
      },
    },
    iSkill: {
      type: Number,
      default: 0,
    },
    iSection: {
      type: Number,
      default: 0,
    },
    iExercise: {
      type: Number,
      default: 0,
    },
  },
  mixins: [
      constantTestBuilder,
  ],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("contentTestBuilderStore", ["skills"]),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    skill_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.skill_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setSkillId", value);
      },
    },
    section_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.section_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setSectionId", value);
      },
    },
    exercise_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.exercise_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setExerciseId", value);
      },
    },
    indexSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSkill", value);
      },
    },
    indexSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSection", value);
      },
    },
    indexExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexExercise", value);
      },
    },
    dialogExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogExercise", value);
      },
    },
    type_form_exercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_exercise;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setTypeFormExercise",
          value
        );
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementFormDisplay",
          value
        );
      },
    },
    dialogSortExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSortExercise;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSortExercise",
          value
        );
      },
    },
    element_audio_section: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_section;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementAudioSection",
          value
        );
      },
    },
    type_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeElement", value);
      },
    },
    dialogAddAudioTime: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogAddAudioTime;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogAddAudioTime",
          value
        );
      },
    },
    dialogSubElement: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSubElement;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSubElement",
          value
        );
      },
    },
    type_form_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormElement", value);
      },
    },
    model_sub_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.model_sub_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setModelSubElement", value);
      },
    },
  },
  methods: {
    editExercise(
      indexSkill,
      indexSection,
      indexExercise,
      skill_id,
      section_id,
      exercise_id
    ) {
      this.type_form_exercise = "update";
      this.dialogExercise = true;
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.skill_id = skill_id;
      this.section_id = section_id;
      this.exercise_id = exercise_id;
      this.element_form_display = "element";
    },
    deleteExercise(indexSkill, indexSection, indexExercise, exercise_id) {
      let seft = this;
      let total_question = 0;
      let type =
        seft.skills[indexSkill].sections[indexSection].exercises[indexExercise]
          .exercise_type;
      if (type == this.exercise_type_constant.MATCHING) {
        total_question =
          seft.skills[indexSkill].sections[indexSection].exercises[
            indexExercise
          ].body.correct.length;
      } else if (type == this.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
        total_question =
          seft.skills[indexSkill].sections[indexSection].exercises[
            indexExercise
          ].body.length;
      } else if (type == this.exercise_type_constant.SHORT_ANSWER) {
        total_question = (
          seft.skills[indexSkill].sections[indexSection].exercises[
            indexExercise
          ].body.match(/{{(.*?)}}/g) || []
        ).length;
      } else {
        total_question =
          seft.skills[indexSkill].sections[indexSection].exercises[
            indexExercise
          ].total_question;
      }
      let data = {
        total_question,
      };
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa thông tin này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          seft.is_call_api = true;
          ApiService.delete("prep-app/test/draft/exercise/" + exercise_id, data)
            .then(function (res) {
              if (res.status == 200) {
                for (
                  let iExer =
                    seft.skills[indexSkill].sections[indexSection].exercises
                      .length - 1;
                  iExer >= seft.indexExercise + 1;
                  iExer--
                ) {
                  seft.skills[indexSkill].sections[indexSection].exercises[
                    iExer
                  ].offset_question -= total_question;
                }
                for (
                  let iSec = seft.skills[indexSkill].sections.length - 1;
                  iSec >= seft.indexSection + 1;
                  iSec--
                ) {
                  seft.skills[indexSkill].sections[iSec].offset_question -=
                    total_question;
                }
                seft.skills[indexSkill].sections[indexSection].total_question -=
                  total_question;
                seft.skills[indexSkill].sections[indexSection].exercises.splice(
                  indexExercise,
                  1
                );
                seft.$set(seft.skills);
                seft.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              if (error) {
                seft.is_call_api = false;
                seft.$toasted.error(
                  "Thao tác không thành công. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  }
                );
              }
            });
        }
      });
    },
    openDialogSortExercise(indexSkill, indexSection, section) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.section_id = section.id;
      this.dialogSortExercise = true;
    },
    openDialogAudioTime(
      indexSkill,
      indexSection,
      indexExercise,
      section,
      exercise
    ) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.element_audio_section = section.element_data;
      this.exercise_id = exercise.id;
      this.type_element = section.element_type;
      this.dialogAddAudioTime = true;
    },
    openDialogSubElement(
      indexSkill = null,
      indexSection = null,
      indexExercise = null,
      section_id = null,
      exercise_id = null,
      model_sub_element = null
    ) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.model_sub_element = model_sub_element;
      this.section_id = section_id;
      this.exercise_id = exercise_id;
      this.type_form_element = "add";
      this.element_form_display = null;
      this.dialogSubElement = true;
    },
    editSubElement(
      indexSkill = null,
      indexSection = null,
      indexExercise = null,
      section_id = null,
      exercise_id = null,
      model_sub_element = null
    ) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.model_sub_element = model_sub_element;
      this.section_id = section_id;
      this.exercise_id = exercise_id;
      this.type_form_element = "update";
      this.element_form_display = null;
      this.dialogSubElement = true;
    },
    deleteSubElement(
      indexSkill = null,
      indexSection = null,
      indexExercise = null
    ) {
      let vm = this;
      let data = {
        exercise_id:
          vm.skills[indexSkill].sections[indexSection].exercises[indexExercise]
            .id,
      };
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa thông tin này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/test/draft/exercise/delete-sub-element",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                self.is_call_api = true;
                vm.skills[indexSkill].sections[indexSection].exercises[
                  indexExercise
                ].sub_element_data = null;
                vm.skills[indexSkill].sections[indexSection].exercises[
                  indexExercise
                ].sub_element_type = null;
                vm.skills[indexSkill].sections[indexSection].exercises[
                  indexExercise
                ].sub_element_id = null;
                vm.$set(vm.skills);
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              if (error) {
                vm.is_call_api = false;
                vm.$toasted.error(
                  "Thao tác không thành công. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  }
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style></style>
